.App {
  text-align: center;
}
@font-face {
  font-family: "Avantgarde";
  src: url(../public/font.ttf) format("truetype");
}

.logo-font {
  user-select: none;
  font-family: "Avantgarde";
}

.logo-back {
  width: 900px;
  position: absolute;
}
#wrapper {
  position:relative;
  width: 100vw;
  height: 10vh;
  max-width:100%;  /* added */
  /*animation: logo-move 1 5s linear*/
}
.logo {
  height: 1220px;
  width: 900px;
  position: relative;
}

.invisibleCard {
  background-color: transparent !important;
  border: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;  
}

.cardAnimate {
  transition: 
    background-color 1s ease-in-out 2s, 
    border 3s ease-in-out, 
    box-shadow 1s ease-in-out !important;
}
.global-wrap {
  max-height: 100vh;
  overflow: hidden;
  font-family: "Avantgarde";
}

.invisibleText {
  opacity: 0;
}

.textAnimate {
  transition: opacity 1s ease-in-out 2s !important
}


.logo-arbeitssaal {
  position:absolute;
  font-size: 171px;
  font-weight: 600;
  left: -500vw;
  top: 10px

}
.logo-gatr {
  position:absolute;
  rotate: 270deg;
  top: 650px;
  left: -167px;
  font-size: 374px;
}
.logo-obe {
  font-size: 375px;
  position:absolute;
  rotate: -90deg;
  transform-origin: 113.6px 316.4px;
  top: 140px;
  left: 104px;

}

.logo-gatrobe {
  position: relative;
  top: -500vh
}

/*
.logo-arbeitssaal {
  position:absolute;
  font-size: 171px;
  font-weight: 600;
  left: 40px;
  top: 10px

}
.logo-gatr {
  position:absolute;
  rotate: 270deg;
  top: 650px;
  left: -167px;
  font-size: 374px;
}
.logo-obe {
  font-size: 375px;
  position:absolute;
  top: 140px;
  left: 104px;
  transform-origin: 0% 0%;

}*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Animation */
@keyframes logo-move {
  from {
    top: 50vw
  }
  to {
    top: 0

  }
}


#mover {
  position: relative;
}

.MuiPaper-root {
  overflow: visible !important;
}

.MuiStack-root {
  overflow: visible !important;
}